export default defineNuxtRouteMiddleware(async () => {
	const routeUrl = useRouteUrl()

	const user = useSupabaseUser().value

	if (!user) {
		console.log("auth-redirect")
		return navigateTo(routeUrl.StartUrl())
	}
})
